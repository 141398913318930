import { includes, each } from 'lodash';
import { ElMessage } from 'element-plus';
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import { getToken } from '@/hooks/token';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/',
    name: 'Admin',
    component: () => import('../views/admin/Index.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const whiteList = ['/login', 'auth-redirect'];

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' });
    } else if (store.getters.userRoles.length) {
      next();
    } else {
      try {
        await store.dispatch('getUserInfo');
        const routers = await store.dispatch('getRoutes');
        each(routers, (route) => {
          router.addRoute(route);
        });
        next({ ...to, replace: true });
      } catch (error) {
        await store.dispatch('resetToken');
        ElMessage.error(error || 'Has Error');
        next(`/login?redirect=${to.path}`);
      }
    }
  } else if (includes(whiteList, to.path)) {
    next();
  } else {
    next(`/login?redirect=${to.path}`);
  }
});

export default router;
