import { map } from 'lodash';
import { createStore } from 'vuex';
import request from '@/hooks/request';
import { getToken, removeToken } from '../hooks/token';

function generaMenu(data) {
  return map(data, (item) => {
    const children = item.child ? generaMenu(item.child) : [];
    return {
      path: item.path === '#' ? `${item.id}_key` : `${item.path}`,
      component: () => {
        if (item.component === '#') {
          return import('@/views/admin/Index.vue');
        }
        // eslint-disable-next-line prefer-template
        return import('@/views' + item.component + '.vue');
      },
      hidden: item.hidden,
      children,
      name: item.name,
      meta: { title: item.title, id: item.id, icon: item.icon },
    };
  });
}

export default createStore({
  state: {
    user: null,
    token: getToken(),
    routes: [],
  },
  getters: {
    token: (state) => state.token,
    userRoles: (state) => {
      if (state.user && state.user.roles && state.user.roles.length) {
        return state.user.roles;
      }
      return [];
    },
    routes: (state) => state.routes,
    user: (state) => state.user,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },
  },
  actions: {
    resetToken({ state }) {
      return new Promise((resolve) => {
        state.token = '';
        removeToken();
        resolve();
      });
    },
    getUserInfo({ state }) {
      return new Promise((resolve, reject) => {
        request.get('/admin/user/info').then((data) => {
          if (!data) {
            return reject(new Error('验证失败，请重新登录！'));
          }
          if (data.roles && data.roles.length) {
            state.user = data;
            return resolve(data);
          }
          return reject(new Error('用户角色非法！'));
        }).catch((error) => reject(error));
      });
    },
    getRoutes({ state }) {
      return new Promise((resolve) => {
        request.get('/admin/user/menu').then((data) => {
          const routers = generaMenu(data);
          state.routes = routers;
          resolve(routers);
        });
      });
    },
  },
  modules: {
  },
});
