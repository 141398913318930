/* eslint-disable import/no-cycle */
import axios from 'axios';
import { cloneDeep, includes, isNull } from 'lodash';
import { ElMessageBox, ElMessage } from 'element-plus';
import store from '../store';
import { getToken } from './token';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    const reConfig = cloneDeep(config);
    if (store.getters.token) {
      reConfig.headers.Authorization = `bearer ${getToken()}`;
    }
    return reConfig;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.status !== 'success') {
      ElMessage({
        type: 'error',
        message: res.message || 'Error',
        duration: 5000,
      });
      if (includes([50008, 50012, 50014], res.code)) {
        ElMessageBox.confirm('您已被登出，可以取消继续留在该页面，或者重新登录。', '确定登出？', {
          type: 'warning',
          confirmButtonText: '重新登录',
        }).then(() => {
          store.dispatch('resetToken').then(() => {
            window.location.reload();
          });
        }).catch(() => {});
      }
      return Promise.reject(new Error(res.message || 'Error'));
    }
    if (!isNull(res.message)) {
      ElMessage({
        type: 'success',
        message: res.message,
        duration: 5000,
      });
    }
    return res.data;
  },
  (error) => {
    console.log('Error: ', error);
    ElMessage({
      type: 'error',
      message: error.message,
      duration: 5000,
    });
    return Promise.reject(error);
  },
);

export default service;
