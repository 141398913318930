import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import TJUI from '@tj/ui';
import App from './App.vue';
import router from './router';
import store from './store';

import '@tj/ui/dist/tj-ui.css';

import '@/assets/less/index.less';

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, {
    size: 'mini',
    locale: zhCn,
  })
  .use(TJUI)
  .mount('#app');
